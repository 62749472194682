<template>
  <div>
    <el-tabs v-model="form.target_type"
             @tab-click="search">
      <el-tab-pane label="知识点讲解"
                   name="2">
      </el-tab-pane>
      <el-tab-pane label="专题讲解"
                   name="1"></el-tab-pane>
    </el-tabs>

    <el-form :model="form"
             label-width="80px"
             ref="formRef">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="年级">
            <el-select v-model="form.grade_id"
                       filterable
                       clearable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="科目">
            <el-select v-model="form.subject_id"
                       filterable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="文件名称">
            <el-input placeholder="请输入文件名称"
                      v-model="form.keyword"
                      style="width:100%" />
          </el-form-item>
        </el-col>

      </el-row>
      <el-row :gutter="10"
              style="margin-bottom:10px">
        <el-col :span="12">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="toUpload">上传</el-button>
          <el-button type="primary"
                     :disabled="!this.selectArr.length"
                     @click="batchEdit">批量修改</el-button>
        </el-col>
      </el-row>
    </el-form>
    <gongyiForm ref="gongyiFormRef" />
    <knowledgeForm ref="knowledgeFormRef" />
    <uploadAgain ref="uploadAgainRef" />
    <batch ref="batchRef" />

    <Table ref="TablesRef"
           :showSelection="true"
           @setArr="setArr"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import gongyiForm from './components/gongyiForm.vue'
import knowledgeForm from './components/knowledge.vue'
import uploadAgain from './components/uploadAgain.vue'
import batch from './components/batchEdit.vue'
import { getUserSubject } from '@/api/subject.js'

export default {

  data () {
    return {
      form: {
        subject_id: '',
        geade_id: '',
        keyword: '',
        target_type: '2',
      },
      subjectList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      gradeList: [],
      showSelection: [],
      selectArr: [],
      pageName: 'video_resource_upload_video',
      tableColumns: [
        { prop: 'id', label: '编号', width: '100', align: 'center' },
        { prop: 'filename', label: '文件名称', align: 'center' },
        { prop: 'subject_name', label: '科目', align: 'center' },
        { prop: 'knowledge_desc', label: '知识点', align: 'center' },
        { prop: 'textbook_name', label: '教材', align: 'center' },
        { prop: 'chapter_desc', label: '章节', align: 'center' },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '预览', type: 'lookVideo', event: 'preview' },
            { content: '重新上传', type: 'reUpload', event: 'again' },
            { content: '修改', type: 'edit', event: 'edit' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      userInfo: {}
    }
  },
  components: {
    gongyiForm, knowledgeForm, uploadAgain, batch
  },
  mounted () {
    this.getList()
    this.setTablesColums()
  },
  methods: {
    again (row) {
      let form = {
        id: row.id,
        subject_id: row.subject_id,
        file: 'video',
        filename: ''
      }
      this.$refs.uploadAgainRef.form = form
      this.$refs.uploadAgainRef.dialogVisible = true
    },
    batchEdit () {
      let form = {
        id: this.selectArr.map(item => item.id),
        target_type: '',
        subject_id: '',
        grade_id: [],
        knowledge_id: [],
        textbook_id: '',
        chapter_id: [],
        question_type_id: [],
      }
      this.$refs.batchRef.form = form
      this.$refs.batchRef.getList()
      this.$refs.batchRef.dialogVisible = true
    },
    setArr (arr) {
      this.selectArr = arr
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getList () {
      this.$http({
        url: '/api/v1/public/subject_new',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data
      })
      this.getSubject()
    },
    async getSubject () {
      const { data } = await getUserSubject()
      this.subjectList = data.list
      this.form.subject_id = this.subjectList[0].subject_id
      this.search()
    },
    initData (page, limit) {
      if (!this.form.subject_id) {
        return
      }
      this.selectArr = []
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v1/resource/lst',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list.map(item => {
          item.chapter_desc = item.chapter_desc ? item.chapter_desc.join(',') : ''
          item.knowledge_desc = item.knowledge_desc ? item.knowledge_desc.join(',') : ''

          return item
        })
        this.totalElements = res.data.count
      })
    },
    handleClick (e) { },
    preview (row) {
      window.open(row.hash_id, '_blank')
    },
    edit (row) {
      if (this.form.target_type == 2) {
        // 知识点讲解
        var ref = 'knowledgeFormRef'
      } else {
        var ref = 'gongyiFormRef'
      }
      let form = {
        id: row.id,
        target_type: Number(row.target_type),
        grade_id: row.grade_id,
        subject_id: row.subject_id,
        knowledge_id: row.knowledge_id,
        chapter_id: row.chapter_id,
        question_type_id: row.question_type_id,
        question_type_id: row.question_type_id,
        textbook_id: row.textbook_id ? row.textbook_id : '',
        filename: row.filename,
        file: 'video',
      }
      this.$refs[ref].form = form
      this.$refs[ref].getList()
      this.$refs[ref].text = '修改'
      this.$refs[ref].getGradeList(2)
      // 查找知识点，章节父级，组建完整路由回显级联选择框
      this.$refs[ref].parentComponentEvent(form)
      this.$refs[ref].dialogVisible = true
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/resource/del',
          method: 'post',
          data: {
            id: row.id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    toUpload () {
      let form = {
        target_type: Number(this.form.target_type),
        grade_id: [],
        subject_id: '',
        knowledge_id: [],
        chapter_id: [],
        question_type_id: [],
        textbook_id: '',
        filename: '',
        file: 'video',
      }

      if (this.form.target_type == 2) {
        var ref = 'knowledgeFormRef'
      } else {
        var ref = 'gongyiFormRef'
      }
      this.$refs[ref].getList()
      this.$refs[ref].text = '上传'
      this.$refs[ref].form = form
      this.$refs[ref].dialogVisible = true


    },
    toAdjutList () {
      this.$router.push('/schoolSource/suditList')
    },
    setSubject (id) {
      this.form.subjectId = id
      this.search()
    },
    setTypeValue (value) {
      this.form.type = value
      this.search()
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  font-size: 19px;
  width: 150px;
  text-align: center;
  padding-right: 10px;
  padding: 0;
}
.is_active {
  color: #409eff;
}
.subject {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.status {
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}
.weishanhe {
  color: #3f9eff;
  background: #eff8ff;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.pass {
  color: #41d7d7;
  background: #effffe;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.notpass {
  color: #fe765f;
  background: #ffefef;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.line {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.table_line {
  width: 100%;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
</style>